import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData, sendToServiceBus } from '../api';
import {FlatAddress} from '../api'
import { GridCallbackDetails, GridPaginationModel } from '@mui/x-data-grid';
interface DataTableState {
  data: FlatAddress[];
  page: number;
  rowsPerPage: number;
  filters: { id: string; title: string; body: string };
  total: number;
  sendingToSbStatus: string | null;
  sendingToSbState: SbState | null;
  loading: boolean;
  error: string | null;
  paginationModel: GridPaginationModel;
  labels: string[]
}
export enum SbState
{
  Success,
  Pending,
  Failed
}

const initialState: DataTableState = {
  data: [],
  labels: [],
  page: 0,
  rowsPerPage: 10,
  paginationModel: {
    page: 0,
    pageSize: 20,
  },
  filters: { id: '', title: '', body: '' },
  total: 0,
  loading: false,
  error: null,
  sendingToSbStatus: null,
  sendingToSbState: null
};

export const fetchPosts = createAsyncThunk(
  'dataTable/fetchPosts',
  async ({ page, rowsPerPage, selectedLabels, changedPrice, searchedText }: { page: number; rowsPerPage: number, selectedLabels: string[], changedPrice: string, searchedText: string }) => {
    const response = await fetchData(page, rowsPerPage, selectedLabels, changedPrice, searchedText);
      return response;
  }
);

export const sendToSb = createAsyncThunk(
  'dataTable/sendToSb',
  async ()  => {
    console.log("aaaa");
    const response = await sendToServiceBus();
      return response;
  }
);

const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 0; // Reset to first page
    },
    setFilters: (state, action: PayloadAction<{ id: string; title: string; body: string }>) => {
      state.filters = action.payload;
    },
    setPaginationModel: (state, action: PayloadAction<{ model: GridPaginationModel; details: GridCallbackDetails }>) => {
      state.paginationModel = action.payload.model; 
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload?.Flats ?? [];
        state.total = action.payload?.Count ?? 0;
        state.labels = action.payload?.Labels ?? [];
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch data';
      })
      .addCase(sendToSb.pending, (state) => {
        state.sendingToSbStatus = "Requesting download"
        state.sendingToSbState = SbState.Pending
        })
        .addCase(sendToSb.fulfilled, (state, action) => {
          state.sendingToSbStatus = "Download requested";
          
        })
        .addCase(sendToSb.rejected, (state, action) => {
          state.sendingToSbStatus = "Download request failed";
        });
  },
});

export const { setPage, setRowsPerPage, setFilters, setPaginationModel } = dataTableSlice.actions;
export default dataTableSlice.reducer;
