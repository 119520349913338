import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import { useDemoData } from '@mui/x-data-grid-generator';

import {
  fetchPosts,
  sendToSb,
  setPaginationModel,
} from "../redux/dataTableSlice";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  SelectChangeEvent,
  SelectInputProps,
} from "@mui/material/Select/SelectInput";
import { InputOutlined } from "@mui/icons-material";

const DataTablePage: React.FC = () => {
  const [changedPrice, setChangedPrice] = useState('indeterminate'); // Initial state
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleChangePrice = () => {
    switch (changedPrice) {
      case 'unchecked':
        setChangedPrice('checked');
        break;
      case 'checked':
        setChangedPrice('indeterminate');
        break;
      case 'indeterminate':
        setChangedPrice('unchecked');
        break;
      default:
        setChangedPrice('unchecked');
    }
  };  

  const isIndeterminate = changedPrice === 'indeterminate';
  const isChecked = changedPrice === 'checked';
  
  const dispatch = useDispatch<AppDispatch>();
  const {
    sendingToSbStatus,
    data,
    labels,
    paginationModel,
    total,
    loading,
  } = useSelector((state: RootState) => state.dataTable);
  const user = useSelector((state: RootState) => state.user.user);
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedLabels>) => {
    const {
      target: { value },
    } = event;
    setSelectedLabels(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    if (!isSearchClicked) {

    dispatch(
      fetchPosts({
        page: paginationModel.page + 1,
        rowsPerPage: paginationModel.pageSize,
        selectedLabels,
        changedPrice,
        searchedText: inputValue.trim(),

      })
    );
  }
  }, [dispatch, paginationModel, user, selectedLabels, changedPrice, isSearchClicked]);

  const handleSearch = () => {
    setIsSearchClicked(true); // Set search clicked to true
    dispatch(
      fetchPosts({
        searchedText: inputValue.trim(),
        page: paginationModel.page + 1,
        rowsPerPage: paginationModel.pageSize,
        selectedLabels,
        changedPrice
      })
    );
    setIsSearchClicked(false); // Reset search clicked to false after search
  };
  // if (!user) {
  //   return <p>Please log in to view the data table.</p>;
  // }

  const dateGetter = (params: any) => {
    return new Date(params);
  };
  const columns: GridColDef[] = [
    // { field: 'Id', headerName: 'ID', width: 150, },
    {
      field: "Link",
      headerName: "Price",
      width: 150,
      renderCell: (params) => (
        <Link href={params.value} target="_blank" rel="noopener">
          {params.row.Price}
        </Link>
      ),
    },
    { field: "FormattedAddress", headerName: "Address", width: 300 },
    {
      field: "FloorSize",
      headerName: "Floor Size",
      type: "number",
      width: 150,
    },

    { field: "Size", headerName: "Size", width: 100 },
    { field: "CustomLabel", headerName: "Custom Label", width: 150 },
    {
      field: "IsDeleted",
      headerName: "Is Deleted",
      type: "boolean",
      width: 150,
    },
    {
      field: "IsAvailable",
      headerName: "Is Available",
      type: "boolean",
      width: 150,
    },
    { field: "PriceHistory", headerName: "Price History", width: 250 },
    {
      field: "UpdatedDate",
      headerName: "Updated Date",
      type: "dateTime",
      width: 200,
      valueGetter: dateGetter,
    },
    {
      field: "DownloadedDate",
      headerName: "Downloaded Date",
      type: "dateTime",
      width: 200,
      valueGetter: dateGetter,
    },
    { field: "Name", headerName: "Name", width: 400 },
  ];


  return (
    <div style={{ height: '100%', width: '100%'}}>
      <div style={{ marginBottom: "16px" }}>
        <Button
          onClick={async () => {
            dispatch(sendToSb());
          }}
          className="custom-button"
          variant="contained"
        >
          Send to SB
        </Button>
        {sendingToSbStatus && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {sendingToSbStatus}
          </Alert>
        )}
      </div>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, flexWrap: "nowrap" }}>
  {/* Container for TextField & Select */}
  <Box sx={{ display: "flex", flexDirection: "column", width: 400 }}>
    <TextField label="Enter text"  variant="outlined" sx={{ mb: 1 }}   value={inputValue} onChange={handleInputChange} />
    <Button
          onClick={async () => {
            dispatch(handleSearch);
          }}
          className="custom-button"
          variant="contained"
        >
          Search
        </Button>
    <FormControl>
      <InputLabel id="demo-multiple-checkbox-label">Labels</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedLabels}
        onChange={handleChange}
        input={<OutlinedInput label="Labels" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {labels.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selectedLabels.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>

  {/* Checkbox */}
  <FormGroup>
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={handleChangePrice}
        />
      }
      label="Changed price"
    />
  </FormGroup>
</Box>

<div>
      <DataGrid
            pageSizeOptions={[5, 10, 25]}

  loading={loading}
  getRowId={(row) => row.Id}
        rows={data}
        columns={columns}
        onPaginationModelChange={(
          model: GridPaginationModel,
          details: GridCallbackDetails<any>
        ) => {
          dispatch(setPaginationModel({ model, details }));
        }}
        pagination
        paginationModel={paginationModel}
        rowCount={total}
        paginationMode="server"
        autoHeight

      />
      </div>
    </div>
  );
};
export default DataTablePage;
